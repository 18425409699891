import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import "fontsource-open-sans"
import '../sass/styles.scss'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const FacebookImg = ({height}) => {
  return (
    <React.Fragment>
      <a href={"https://www.facebook.com/RadioNacionalDeColombia"} target={"_blank"} rel="noopener  noreferrer">
        <img src={require('../images/facebook.svg')} height={height ? height : 50} alt={"Facebook Radio Nacional"} />
      </a>
    </React.Fragment>
  )
}

const TwitterImg = ({height}) => {
  return (
    <React.Fragment>
      <a href={"https://twitter.com/Radnalco"} target={"_blank"} rel="noreferrer">
        <img src={require('../images/twitter.svg')} height={height ? height : 50} alt={"Twitter Radio Nacional"} />
      </a>
    </React.Fragment>
  )
}

const InstagramImg = ({height}) => {
  return (
    <React.Fragment>
      <a href={"https://instagram.com/radionacionalco"} target={"_blank"} rel="noreferrer">
        <img src={require('../images/instagram.svg')} height={height ? height : 50} alt={"Instagram Radio Nacional"} />
      </a>
    </React.Fragment>
  )
}


const HeaderVideo = () => {
  return (
    <header class="main-top withVideo">
      <StaticQuery
        query={queryFileMp4}
        render={data => (
          <video id="video-background" class="video" autoPlay muted loop>
            <source src={data.file.publicURL} type="video/mp4" />
          </video>
        )}
      />
      <div class="over-video">
        <div class="top-bar">
          <Container maxWidth={'md'} justify={"space-between"} align={"left"}>
            <Grid container alignItems={'center'}>
              <Grid item xs={6} align={'left'}>
                <div class="logo">
                  <img src={require('../images/logo.svg')} height={50} alt={"Micrositio Campesinos"} />
                  <img src={require('../images/logo-text.svg')} height={60} alt={"Micrositio Campesinos"} />
                </div>
              </Grid>
              <Grid item xs={6} align={'right'}>
                <div class="social">
                  <TwitterImg height={60} />
                  <FacebookImg height={60} />
                  <InstagramImg height={60} />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div class="content-text">
          <Container maxWidth={'md'} justify={"center"} align={"center"}>
            <div class="logo">
              <img src={require('../images/logo-text.svg')} width={390} alt={"Micrositio Campesinos"} />
            </div>
            <p>Campesinos y pescadores garantizaron la soberanía alimentaria del país durante la pandemia, incluso enfrentando la crisis económica y social derivada de la misma y que puso en riesgo la continuidad de su labor. Este trabajo de investigación recorre tres regiones de Colombia (Boyacá, Putumayo y el Golfo de Morrosquillo) y recoge sus voces para conocer el impacto del Covid-19 en el campo colombiano.</p>
          </Container>
        </div>
        <a class={"scroll-down"} href={"#chapters"}>
          <p>Desplázate para continuar</p>
          <ArrowDropDownIcon />
        </a>
      </div>
    </header>
  )
}

const HeaderGeneral = () => {
  return (
    <header class="main-top">
      <div class="top-bar">
        <Container maxWidth={'md'} justify={"space-between"} align={"left"}>
          <Grid container alignItems={'center'}>
            <Grid item xs={6} align={'left'}>
              <Link to={"/"}>
                <div class="logo">
                  <img src={require('../images/logo.svg')} height={50} alt={"Micrositio Campesinos"} />
                  <img src={require('../images/logo-text.svg')} height={60} alt={"Micrositio Campesinos"} />
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} align={'right'}>
              <div class="social">
                <TwitterImg height={60} />
                <FacebookImg height={60} />
                <InstagramImg height={60} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </header>
  )
}


const Page = (props) => {
  const { headerVideo } = props;

  return (
    <React.Fragment>
      {headerVideo ? <HeaderVideo /> : <HeaderGeneral />}
      <main class={`main ${props.type ? "page-" + props.type : null}`}>
        {props.children}
      </main>
      <footer class="main-bottom">
        <Container maxWidth={'md'}>
          <Grid container alignItems={'center'} justify={'center'}>
            <Grid item xs={12} sm={6} align={'center'}>
              <div class="logo">
                <img src={require('../images/logo.svg')} height={60} alt={"Micrositio Campesinos"} />
                <img src={require('../images/logo-text.svg')} height={65} alt={"Micrositio Campesinos"} />
              </div>
              <div class="social">
                <a href={"https://www.radionacional.co/"} target={"_blank"} rel="noreferrer">
                  <img src={require('../images/radional.svg')} height={80} alt={"Radio Nacional"} />
                </a>
                <TwitterImg height={50} />
                <FacebookImg height={50} />
                <InstagramImg height={50} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} align={'flex-start'}>
              <h3 class='credits-title'>Créditos</h3>
              <p class='credits'><strong>Investigación y Dirección:</strong> Carolina Bustamante Gutiérrez.  <strong>Producción sonora:</strong> Andrés Lozano. <strong>Producción audiovisual:</strong> Miguel Esteban Herrera. <strong>Edición de textos:</strong> Laura Quiceno. <strong>Diseño:</strong> Harol Beltrán. <strong>Desarrollo Web:</strong> Cristian Anzola, Edward Ortíz, Harold Giraldo  <strong>Cámara:</strong> Nangibe Torres y Sandra Martin.</p>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </React.Fragment>
  )
}

// Query Single
const queryFileMp4 = graphql`
  query {
   file(ext: {eq: ".mp4"}, dir: {regex: "/(videoHome)/"}) {
      publicURL
    }
  }
`


export default Page